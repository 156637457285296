.chat-sidebar {
  .cursor-pointer {
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &.bg-primary {
      &:hover {
        background-color: var(--bs-primary) !important;
      }
    }
  }
}

.chat-window {
  .message-bubble {
    max-width: 70%;
    min-width: 20vw;
    padding: 8px 12px;
    border-radius: 8px;
    margin: 4px 0;

    &.sent {
      background-color: var(--bs-primary);
      color: white;

      .message-time {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    &.received {
      background-color: #f0f2f5;

      .sender-name {
        color: var(--bs-gray-700);
        font-weight: 500;
        display: block;
        margin-bottom: 4px;
      }

      .message-time {
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .message-content {
      margin-bottom: 4px;
    }

    .message-time {
      font-size: 12px;
      display: block;
    }

    .message-reactions {
      display: flex;
      gap: 4px;
      margin-top: 4px;
      flex-wrap: wrap;

      .reaction {
        display: inline-flex;
        align-items: flex-end;
        background-color: rgba(0, 0, 0, 0.05);
        padding: 2px 6px;
        border-radius: 12px;
        font-size: 12px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          // background-color: rgba(14, 8, 8, 0.1);
          transform: scale(1.05);
        }

        .reaction-count {
          margin-left: 14px !important;
          font-size: 50px;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }

  .message-list {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.chat-container {
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 250px;
  width: calc(100vw - 250px) !important;
  background: #fff;
  z-index: 999;
  transition: all 0.3s ease;

  .mini-sidebar & {
    left: 60px;
  }

  .chat-row {
    height: 100%;
    display: flex;
    margin: 0;
    overflow: hidden;
  }

  .chat-sidebar-col {
    width: 300px;
    min-width: 300px;
    height: 100%;
    border-right: 1px solid #e8e8e8;
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .chat-sidebar-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;

      .sidebar-header {
        flex-shrink: 0;
        padding: 16px;
        background: #fff;
        border-bottom: 1px solid #e8e8e8;
        z-index: 2;

        .ant-input-search {
          margin-bottom: 16px;
        }

        .ant-tabs {
          .ant-tabs-nav {
            margin: 0;
          }
        }
      }

      .sidebar-content {
        flex: 1;
        overflow-y: auto;
        background: #fff;

        .ant-list {
          height: 100%;

          .ant-empty {
            padding: 40px 16px;
            color: rgba(0, 0, 0, 0.45);

            .ant-empty-image {
              height: 40px;
              margin-bottom: 8px;
            }

            .ant-empty-description {
              color: rgba(0, 0, 0, 0.45);
            }
          }

          .ant-list-item {
            padding: 12px 16px;
            cursor: pointer;
            border-bottom: 1px solid #f0f0f0;
            margin: 0;
            transition: all 0.3s ease;

            &:hover {
              background-color: #f5f5f5;
            }

            &.selected {
              background-color: #e6f7ff;
            }

            .ant-list-item-meta {
              flex: 1;
              margin-bottom: 0;
              align-items: center;

              .ant-list-item-meta-avatar {
                margin-right: 12px;
              }

              .ant-list-item-meta-content {
                flex: 1;
                min-width: 0;

                .ant-list-item-meta-title {
                  margin-bottom: 4px;
                  font-weight: 500;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .ant-list-item-meta-description {
                  color: rgba(0, 0, 0, 0.45);
                  font-size: 12px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }

            .delete-button {
              opacity: 0;
              transition: opacity 0.2s ease;
              padding: 4px;
              margin-left: 8px;

              &:hover {
                color: #ff4d4f;
                background-color: #fff1f0;
              }
            }

            &:hover .delete-button {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .chat-main-col {
    flex: 1;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .button-container {
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    gap: 8px;
    background: #fff;

    button {
      flex: 1;
      height: 36px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .anticon {
        font-size: 12px;
      }
    }
  }

  .chat-list {
    .chat-item {
      padding: 12px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      &.selected {
        background-color: rgba(24, 144, 255, 0.1);
      }

      .chat-item-content {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        width: 100%;

        .chat-info {
          flex: 1;
          min-width: 0;

          .chat-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 4px;

            .member-count {
              font-size: 12px;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          .last-message {
            display: flex;
            align-items: center;
            gap: 8px;

            .ant-typography {
              margin: 0;
            }

            .message-time {
              font-size: 12px;
              white-space: nowrap;
            }
          }
        }

        .action-button {
          opacity: 0;
          transition: opacity 0.3s;
        }

        &:hover .action-button {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 768px) {
    left: 0;

    .mini-sidebar & {
      left: 0;
    }

    .chat-sidebar-col {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      transform: translateX(-100%);
      transition: transform 0.3s ease;

      &.show {
        transform: translateX(0);
      }
    }
  }
}

.chat-window-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;

  .chat-header {
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-content {
      display: flex;
      align-items: center;
      gap: 12px;

      .chat-info {
        .ant-typography {
          margin: 0;
          display: block;
        }

        .group-members {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: 4px;

          .member-avatars {
            display: flex;
            align-items: center;

            .member-avatar-wrapper {
              position: relative;
              display: flex;
              align-items: center;

              .ant-avatar {
                cursor: pointer;
                transition: all 0.3s ease;
                position: relative;
                z-index: 1;

                &:hover {
                  transform: translateY(-2px);
                  z-index: 2;
                }
              }

              .member-actions-btn {
                position: absolute;
                right: -4px;
                top: -4px;
                opacity: 0;
                transition: opacity 0.3s ease;
                z-index: 3;
                padding: 2px;
                height: auto;
                min-width: auto;
                background: rgba(255, 255, 255, 0.9);
                border-radius: 50%;
                border: 1px solid #f0f0f0;

                &:hover {
                  background: #fff;
                }

                .anticon {
                  font-size: 12px;
                }
              }

              &:hover {
                .member-actions-btn {
                  opacity: 1;
                }
              }
            }
          }

          .ant-typography {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
          }
        }
      }
    }

    .header-actions {
      display: flex;
      gap: 8px;

      .ant-btn {
        color: #666;

        &:hover {
          color: #1890ff;
        }
      }
    }
  }

  .message-list {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background: #f5f5f5;

    .message-item {
      position: relative;
      margin: 4px 0;

      &:hover {
        .message-actions {
          opacity: 1;
          visibility: visible;
        }
      }

      .message-content {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        position: relative;
      }

      .message-bubble {
        position: relative;
        max-width: 70%;
        min-width: 20vw;
        padding: 8px 12px;
        border-radius: 8px;
        background: white;
        box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
      }

      .message-actions {
        position: absolute;
        top: 0;
        right: -30px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease;

        .action-button {
          width: 28px;
          height: 28px;
          padding: 0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: white;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);

          &:hover {
            background: #f0f2f5;
          }
        }
      }

      &.own-message {
        // .message-content {
          // justify-content: flex-end;
        // }

        .message-bubble {
          background: #edf3ec;
          min-width: 20vw;
        }

        .message-actions {
          left: auto;
          right: -30px;
        }
      }
    }
  }

  .message-input-area {
    border-top: 1px solid #e8e8e8;
    padding: 12px 16px;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .input-section {
      display: flex;
      align-items: center;
      gap: 8px;

      .input-buttons {
        display: flex;
        gap: 4px;

        .ant-btn {
          height: 36px;
          width: 36px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: #64748b;
          transition: all 0.2s ease;

          &:hover {
            color: #1890ff;
            background: #f0f7ff;
          }
        }
      }

      .ant-input {
        flex: 0.8;
        max-height: 35px !important;
        min-height: 35px !important;
        max-width: 40vw;
        border-radius: 20px;
        padding: 0 16px;
        margin: 0 8px;
        line-height: 32px;

        &:focus {
          box-shadow: none;
          border-color: #555;
        }
      }

      .ant-btn {
        height: 40px;
        width: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &.ant-btn-primary {
          background: #64748b;
          border: none;

          &:hover {
            background: #162029;
          }

          &:disabled {
            background: #d9d9d9;
          }
        }
      }
    }

    .image-preview-section {
      padding: 12px;
      background: #f8f9fa;
      border-radius: 8px;
      border: 1px solid #e1e4e8;
      position: relative;

      .image-preview {
        position: relative;
        display: inline-block;
        max-width: 200px;

        img {
          width: 100%;
          max-height: 150px;
          object-fit: contain;
          border-radius: 8px;
        }

        .remove-image {
          position: absolute;
          top: -10px;
          right: -10px;
          height: 24px;
          width: 24px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          border: none;
          color: #64748b;
          transition: all 0.2s ease;

          &:hover {
            color: #ef4444;
            background: #fee2e2;
          }
        }

        .upload-progress {
          max-width: 25vw;
          margin: 10px auto;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          background-color: yellow;

          .ant-progress {
            margin-bottom: 0;
            line-height: 1;
            background-color: red;
            max-width: 25vw;

            .ant-progress-inner {
              .ant-progress-bg {
                height: 4px !important;
                max-width: 40vw !important;
              }
            }
          }
        }
      }
    }
  }

  .chat-input-container {
    position: relative;

    .emoji-picker-container {
      position: absolute;
      bottom: 100%;
      left: 0;
      z-index: 1000;

      .EmojiPickerReact {
        --epr-bg-color: #fff;
        --epr-category-label-bg-color: #f0f2f5;

        border: 1px solid #e8e8e8;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .no-chat-selected {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
  }
}

.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-left: 1px solid #f0f0f0;

  .chat-window-header {
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
    background: #fff;

    .chat-info {
      display: flex;
      align-items: center;
      gap: 12px;

      .chat-details {
        flex: 1;

        .chat-name {
          font-size: 16px;
          margin-bottom: 4px;
          display: block;
        }

        .member-list {
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: opacity 0.3s;

          &:hover {
            opacity: 0.8;
          }

          .member-avatar {
            transition: transform 0.3s;

            &:hover {
              transform: translateY(-2px);
            }
          }
        }
      }
    }
  }

  .members-list {
    max-height: 400px;
    overflow-y: auto;

    .member-item {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 8px 0;

      .member-info {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

.chat-window {
  .chat-header {
    .chat-info {
      .chat-details {
        .view-members-btn {
          padding: 0;
          height: auto;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);

          &:hover {
            color: #1890ff;
          }
        }
      }
    }
  }
}

.members-drawer-header {
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    h4 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .members-count {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}

.member-actions {
  opacity: 0;
  transition: opacity 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.member-item:hover {
  .member-actions {
    opacity: 1;
  }
}

.members-list {
  .member-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;

    &:last-child {
      border-bottom: none;
    }

    .member-info {
      display: flex;
      align-items: center;
      flex: 1;

      .member-details {
        margin-left: 12px;

        .member-name {
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 4px;

          .current-user-tag {
            color: rgba(0, 0, 0, 0.45);
            font-weight: normal;
            font-size: 12px;
          }
        }

        .member-role {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }

    .member-actions {
      opacity: 0;
      transition: opacity 0.2s;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    &:hover {
      .member-actions {
        opacity: 1;
      }
    }
  }
}

.ant-modal-confirm-btns {
  .ant-btn-dangerous {
    background-color: #ff4d4f;

    &:hover {
      background-color: #ff7875;
    }
  }
}

.chat-header {
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .chat-info {
    display: flex;
    align-items: center;

    .chat-details {
      margin-left: 12px;

      h3 {
        margin: 0;
        font-size: 16px;
      }
    }
  }

  .chat-actions {
    display: flex;
    gap: 8px;
  }
}

.message-actions {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 4px;

  .reaction-button {
    padding: 4px;
    height: auto;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .reaction-popup {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    bottom: 0;
    background: #1d1c1c !important;
    border-radius: 20px;
    padding: 6px;
    display: flex;
    gap: 4px;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    .reaction-item {
      cursor: pointer;
      padding: 4px 8px;
      font-size: 20px;
      transition: all 0.2s ease;
      color: white;

      &:hover {
        transform: scale(1.2);
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.message-reactions {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;

  .reaction {
    display: inline-flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    padding: 2px 6px;
    border-radius: 12px;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    .reaction-count {
      margin-left: 4px;
      font-size: 11px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

.reaction-menu-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;

  span:first-child {
    font-size: 16px;
  }

  span:last-child {
    font-size: 14px;
  }
}

.reactions-row {
  display: flex;
  gap: 8px;
  padding: 4px 8px;

  .reaction-item {
    cursor: pointer;
    padding: 4px;
    border-radius: 50%;
    transition: all 0.2s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      transform: scale(1.2);
    }

    .reaction-emoji {
      font-size: 100px;
      line-height: 1;
    }
  }
}

.reactions-menu {
  border: none !important;
  line-height: normal !important;

  .ant-menu-item {
    padding: 8px !important;
    margin: 0 4px !important;
    height: auto !important;
    line-height: 1 !important;
    border-bottom: none !important;
    font-size: 20px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05) !important;
      color: inherit !important;
    }

    &::after {
      display: none !important;
    }
  }
}

// Mobile responsiveness
@media (max-width: 768px) {
  .chat-container {
    margin-top: 60px;

    .chat-sidebar-col {
      position: fixed;
      left: 0;
      top: 60px;
      bottom: 0;
      z-index: 100;
      background: #fff;
      transform: translateX(-100%);
      transition: transform 0.3s ease;

      &.show {
        transform: translateX(0);
      }
    }

    .chat-main-col {
      width: 100%;
    }
  }

  .chat-window-container {
    .message-input-area {
      padding: 12px;
    }
  }
}

.add-members-container {
  .member-search {
    margin-bottom: 16px;
  }

  .search-results {
    .search-result-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      border-bottom: 1px solid #f0f0f0;

      &:last-child {
        border-bottom: none;
      }

      .user-info {
        display: flex;
        align-items: center;
        flex: 1;
        margin-right: 12px;

        .user-details {
          margin-left: 12px;
          overflow: hidden;

          .user-name {
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .user-email {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .no-results {
      text-align: center;
      color: rgba(0, 0, 0, 0.45);
      padding: 16px 0;
    }
  }
}

.members-drawer-header {
  .members-count {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

#sender-name {
  color: gray !important;
  font-weight: 500;
  display: block;
  margin-bottom: 4px;
}

.ant-drawer {
  .ant-drawer-content-wrapper {
    margin-top: 60px !important;
    height: calc(100vh - 60px) !important;
  }

  .ant-drawer-content {
    background-color: #f5f5f5;

    .member-item {
      .member-info {
        .member-name {
          color: rgba(0, 0, 0, 0.45);
          font-weight: normal;
          font-size: 12px;
        }

        .member-role {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }

    .member-actions {
      opacity: 0;
      transition: opacity 0.2s;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    &:hover {
      .member-actions {
        opacity: 1;
      }
    }
  }
}

.member-info {
  .member-name {
    color: rgba(0, 0, 0, 0.45);
    font-weight: normal;
    font-size: 12px;
  }

  .member-role {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.member-actions {
  opacity: 0;
  transition: opacity 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
  