@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

.auth {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0faff;
}

.wrapper {
  position: relative;
  max-width: 400px;
  width: 100%;
  overflow: hidden;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.form h2 {
  /* margin-bottom: 10px; */
  margin-top: -10px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: #4c4c4c;
}
.form p {
  color: #4c4c4c;
  font-weight: bold;
}

.wrapper form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

form input {
  padding: 30 15px;
  font-weight: 400;
  color: #333;
  border-radius: 8px;
  background: #fff;
  height: 45px;
  font-size: 14px;
}
.form input::placeholder {
  font-size: 14px;
}
.form img {
  width: 140px;
  height: 120px;
}
.form a {
  color: #ddd;
  text-decoration: none;
}

form a:hover {
  text-decoration: underline;
}

form button[type="submit"] {
  background: linear-gradient(45deg, #cb6ce6, #4c4c4c);
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 20px;
  font-weight: 600;
}

form input[type="submit"]:hover {
  background: linear-gradient(45deg, #ff4b2b, #ff416c);
}

.form-group label {
  float: left;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
  color: #4c4c4c;
}

.form-control {
  line-height: 1 !important;
}